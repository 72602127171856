<template>
  <div class="container p-0">
    <div class="bg-white my-2 p-1">
      <div class="d-flex border-bottom px-2">
        <h5 class="col px-2">小猪佩奇中介</h5>
        <span class="col-2 pe-1 text-r">
            <i class="fa-solid pt-2 fa-angles-right text-muted"></i>
        </span>
      </div>
      <div class="d-flex px-2">
        <span class="col">人事主管/一二一通信</span>
        <span class="col-3 p-0 text-muted"> 2022-01-01 </span>
      </div>
      <span class="px-2 mt-1 text-muted"> 日结 | 小时工 18/20元/小时 </span>
    </div>

    <div class="bg-white my-2 p-1">
      <div class="d-flex border-bottom px-2">
        <h5 class="col px-2">小猪佩奇中介</h5>
        <span class="col-2 pe-1 text-r">
            <i class="fa-solid pt-2 fa-angles-right text-muted"></i>
        </span>
      </div>
      <div class="d-flex px-2">
        <span class="col">人事主管/一二一通信</span>
        <span class="col-3 p-0 text-muted"> 2022-01-01 </span>
      </div>
      <span class="px-2 mt-1 text-muted"> 日结 | 小时工 18/20元/小时 </span>
    </div>


  </div>
</template>

<script>
export default {
  name: "peoviderSettlement",
};
</script>

<style>
</style>