<template>
    <div class="container px-0 pt-2">
        <div class="e-top">
            <div class="e-table bg-white p-2 d-flex text-center">
                <span @click="staff" class="col-6 border-right">
                    员工
                </span>
                <span @click="provider" class="col-6">
                    供应商
                </span>
            </div>
            <search/>
        </div>
        <transition name="fade">
            <staffSettlement v-if="showSraff"></staffSettlement>
        </transition>
        <transition name="fade">
            <providerSettlement v-if="showProvider"></providerSettlement>
        </transition>
    </div>
</template>

<script>
import { ref } from 'vue'

import staffSettlement from '@/components/jobs/settlement/staff'
import providerSettlement from '@/components/jobs/settlement/provider'
export default {
    name:'settlement',
    components: {
        staffSettlement,
        providerSettlement
    },
    setup(){
        let showSraff = ref(false)
        let showProvider = ref(false)
        function staff() {
            showSraff.value = true
            showProvider.value = false
        }
        function provider() {
            showProvider.value = true
            showSraff.value = false
        }
        return {
            showSraff,
            showProvider,
            staff,
            provider
        }
    }

}
</script>

<style>
.e-top {
    position: sticky;
    top: 0;
    z-index: 9999;
}
.e-table span:hover {
    background: #b8dfdbe3;
}
/* .fade-enter {
    opacity: 0;
}
.fade-enter-active{
    opacity: 1;
} */
</style>