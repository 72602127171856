<template>
  <div class="container p-0">
    <div class="mt-3" v-for="(jobs,code) in datas" :key="code">
        <div class="bg-light pb-2">
        <div class="d-flex px-2">
          <h6 class="col pt-2">{{ jobs.name }}</h6>
          <span class="col-3 pt-1 text-r">
            <a :href="`/jobs/settlement/staff/add?id=${jobs.id}`" class="text-muted">
              <i class="fa-solid fa-plus text-muted"></i> 添加
            </a>
          </span>
        </div>
        <div class="d-flex ps-2" @click="isShow(code)">
          <div class="col text-muted">
              日结 | 小时工
          </div>
          <div class="col text-muted">
             <i class="fa-solid pic pt-2 fa-angles-right text-muted"></i>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 bg-white border-top" v-if="showContent == code">
        <table class="table table-striped mb-1">
          <tbody>
            <tr v-for="(value, code) in jobs.settlement" :key="code">
              <th class="p-2 ps-3 pt-2" style="width:45%">{{ value.name }}</th>
              <td class="p-2 col pt-2 text-muted fs-9">{{ value.day_price }}元/小时 模式: 日结</td>
              <td class="p-2 col-2 pr-2 text-r">
                <a :href="`/jobs/settlement/staff/info?id=${value.id}&title=${jobs.name}`" class="text-muted">
                  <i class="fa-solid btn fa-angles-right text-muted"></i>
                </a>
              </td>
            </tr>
          </tbody>

        </table>
        
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
import { SETTLEMENT_TYPE } from '@/lib/data'
export default {
  name: "staffSettlement",
  components: {},
  setup() {
    let showContent = ref('Z')

    const datas = ref([
      {
        id:100, 
        name:"一二一通信技术总监",
        settlement: [
          {id:1, name:"长期日结", day_price:21, settlement_type:0},
          {id:2, name:"长期小时工", day_price:23, settlement_type:3},
          {id:3, name:"长期周结", day_price:22, settlement_type:1}
        ]
      },
      { 
        id:101,
        name:"97打工网高薪招聘",
        settlement: [
          {id:4, name:"长期日结", day_price:25, settlement_type:0},
          {id:5, name:"长期小时工", day_price:28, settlement_type:3},
          {id:6, name:"长期周结", day_price:25.5, settlement_type:1}
        ]
      },
      { 
        id:102,
        name:"网红小广播",
        settlement: [
          {id:4, name:"长期日结", day_price:25, settlement_type:0},
          {id:5, name:"长期小时工", day_price:28, settlement_type:3},
          {id:6, name:"长期周结", day_price:25.5, settlement_type:1}
        ]
      }
    ])
    
    function isShow(a) {
      showContent.value = a
    }

    return {
      SETTLEMENT_TYPE,
      showContent,
      isShow,
      datas
    };
  },
};
</script>

<style scoped>
summary::marker {
  content: "";
  display: none;
}

.pic {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg); /* Internet Explorer */
  -moz-transform:rotate(90deg); /* Firefox */
  -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
  -o-transform:rotate(90deg); /* Opera */
}
.table-striped tbody tr:nth-of-type(odd){
  background-color: rgba(221, 218, 218, 0.15);
}
</style>